const dh_device = {
    state: {
        devices: []
    },
    mutations: {
        SET_DH_DEVICES: (state, devices) => {
            state.devices = devices
        }
    },
    getters: {
        getDhDevices: state => {
          return state.devices
        },
        getDhOnLineDevices: state => {
            if (state.devices && state.devices.length > 0) {
                return state.devices.filter(item => {
                    return item.action === 'Normal'
                })
            } else {
                return []
            }
        },
        getDhOfflineDevice: state => {
            if (state.devices && state.devices.length > 0) {
                return state.devices.filter(item => {
                    return item.action === 'Offline'
                })
            } else {
                return []
            }
        }
    },
    actions: {
        setDHDevices({state, commit}, device) {
            return new Promise(((resolve) => {
                if (!device) {
                    return
                }
                const list = [ ...state.devices ]
                const now = new Date()
                if (list && list.length > 0) {
                    const item = list.find(item => device.deviceId === item.deviceId)
                    if (item) {
                        item.action = device.action
                        item.updatedTime = now.getHours() + ':' + now.getMinutes()
                    } else {
                        device.updatedTime = now.getHours() + ':' + now.getMinutes()
                        list.push(device)
                    }
                }else {
                    device.updatedTime = now.getHours() + ':' + now.getMinutes()
                    list.push(device)
                }
                commit('SET_DH_DEVICES', list)
                resolve()
            }))
        }
    }
}
export default dh_device
