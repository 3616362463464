import { dhAddNewCall, dhRejectCall, dhCarOwnHanUpCall, dhHanUpCall, activeDhCall, dhCallAnswer } from "../../api/dhCall"
import { hmHangUp, hmAnswer, hmCall } from '../../api/hmCall'
const answer = {
  state: {
    answer: undefined,
    hasDHCall: false,
    hasPhoneCall: false,
  },
  mutations: {
    SET_ANSWER: (state, answer) => {
      state.answer = answer
    },
    SET_HAS_DH_CALL: (state, hasCall) => {
      state.hasDHCall = hasCall
    },
    SET_HAS_PHONE_CALL: (state, hasCall) => {
      state.hasPhoneCall = hasCall
    }
  },
  getters: {
    currentAnswer: state => {
      return state.answer
    },
    isAnswering: state => {
      return state.answer !== undefined
    },
    isDhAnswering: state => {
      return state.answer !== undefined && state.answer.source === 1
    },
    isHmAnswering: state => {
      return state.answer !== undefined && (state.answer.source === 3 || state.answer.source === 2)
    },
    isRing: state => {
      return (state.hasDHCall || state.hasPhoneCall) && state.answer === undefined
    }
  },
  actions: {
    setHasDHCall ({commit}, val) {
      return new Promise(((resolve) => {
        commit('SET_HAS_DH_CALL', val)
        resolve()
      }))
    },
    setHasPhoneCall ({commit}, val) {
      return new Promise((resolve => {
        commit('SET_HAS_PHONE_CALL', val)
        resolve()
      }))
    },
    addNewCall(_, deviceNo) {
      return new Promise(((resolve, reject) => {
        dhAddNewCall(deviceNo).then(res => {
          resolve(res)
        }).catch(e => {
          console.error('收到笛虎呼叫，调用新增呼叫接口异常')
          console.error(e)
          reject(e)
        })
      }))
    },
    answerDhCall({commit}, call) {
      return new Promise((resolve, reject) => {
        dhCallAnswer(call.id).then(() => {
          commit('SET_ANSWER', call)
          resolve()
        }).catch(e => {
          console.error('接听呼叫异常')
          console.error(e)
          reject(e)
        })
      })
    },
    rejectDhCall(_, callId) {
      return new Promise((resolve, reject) => {
        dhRejectCall(callId).then(() => {
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    hanUpDhCall({commit, state}) {
      return new Promise((resolve, reject) => {
        dhHanUpCall(state.answer.id).then(() => {
          resolve()
        }).catch(e => {
          reject(e)
        }).finally(() => {
          commit('SET_ANSWER', undefined)
        })
      })
    },
    activeDhCarOwnHanUp(callId) {
      return new Promise(((resolve, reject) => {
        dhCarOwnHanUpCall(callId).then(() => {
          resolve()
        }).catch(e => {
          console.error('车主主动挂断，调用接口异常')
          console.error(e)
          reject(e)
        })
      }))
    },
    activeDhCall({commit}, deviceNo) {
      return new Promise((resolve, reject) => {
        activeDhCall(deviceNo).then(res => {
          commit('SET_ANSWER', res)
          resolve(res)
        }).catch(e => {
          commit('SET_ANSWER', { deviceNo: deviceNo, type: 'dh', parkingName: '未知停车场', channelName: '未知通道' })
          reject(e)
        })
      })
    },
    activeHmCall({commit}, deviceNo) {
      return new Promise( (resolve, reject) => {
        hmCall(deviceNo).then(res => {
          commit('SET_ANSWER', res)
          resolve(res)
        }).catch(e => {
          reject(e)
        })
      })
    },
    answerHmCall ({ commit }, call) {
      return new Promise((resolve, reject) => {
        hmAnswer({ callRecordId: call.id, deviceNo: call.deviceNo}).then(() => {
          commit('SET_ANSWER', call)
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    hangUpHmCall ({ commit, state}) {
      return new Promise((resolve, reject) => {
        hmHangUp({ callRecordId: state.answer.id, deviceNo: state.answer.deviceNo}).then(() => {
          resolve()
        }).catch(e => {
          reject(e)
        }).finally(() => {
          commit('SET_ANSWER', undefined)
        })
      })
    },
    rejectHmCall( _ , call) {
      console.info(call)
      return new Promise((resolve, reject) => {
        hmHangUp({ callRecordId: call.id, deviceNo: call.deviceNo}).then(() => {
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
}

export default answer
