import request from "../utis/request";

const callApi = {
    dhAddNewCall: '/unattended/dh-call/call',
    dhCallAnswer: '/unattended/dh-call/answer',
    dhRejectCall: '/unattended/dh-call/reject',
    dhHanUpCall: '/unattended/dh-call/han-up/',
    dhCarOwnHanUpCall: '/unattended/dh-call/active-han-up',
    dhActiveCall: '/unattended/dh-call/active-call/'
}

export function dhAddNewCall(deviceNo) {
    return request({
        method: 'POST',
        url: callApi.dhAddNewCall + '/' + deviceNo
    })
}

export function dhCallAnswer(callid) {
    return request({
        method: 'POST',
        url: callApi.dhCallAnswer + '/' + callid
    })
}

export function dhRejectCall(callId) {
    return request({
        method: 'POST',
        url: callApi.dhRejectCall + '/' +callId,
    })
}

export function dhCarOwnHanUpCall(callId) {
    return request({
        method: 'POST',
        url: callApi.dhCarOwnHanUpCall + '/' + callId
    })
}


export function dhHanUpCall(callId) {
    return request({
        method: 'POST',
        url: callApi.dhHanUpCall + callId
    })
}

export function activeDhCall(deviceId) {
    return request({
        method: 'POST',
        url: callApi.dhActiveCall + deviceId
    })
}
