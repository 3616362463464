<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default {
  name: 'Home',
  components: {
  },
  data: function() {
    return {
      height: 0,
      locale: zhCN
    };
  },
  methods: {
  },
  created() {
    this.height = document.body.clientHeight - 67
  }
}
</script>
<style>
#app {
  width: auto;
  height: 100%;
}
</style>
