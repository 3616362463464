import request from "../utis/request";

const reasonApi = {
    list : '/unattended/pass-reason/list',
    add: '/unattended/pass-reason/add',
    delete: '/unattended/pass-reason/delete/'
}

export function reasonList(){
    return request({
        url: reasonApi.list,
        method: 'get'
    })
}

export function reasonAdd(params){
    return request({
        url: reasonApi.add,
        method: 'post',
        data: params
    })
}

export function reasonDelete(id){
    return request({
        url: reasonApi.delete + id,
        method: 'post'
    })
}