import { login, info } from '../../api/login'
import storage from "store";


const user ={
  state: {
    token: '',
    name: '',
    info: {},
    parking: []
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_PARKING: (state, parking) => {
      state.parking = parking
    }
  },
  actions: {
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          storage.set('token', res, 7*24*60*60*1000)
          commit('SET_TOKEN', res)
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        info().then(res => {
          commit('SET_INFO', res)
          commit('SET_NAME', res.username)
          if (res.dutyParkings) {
            commit('SET_PARKING', res.dutyParkings)
          } else {
            console.info('未获取到用户管理的停车场')
          }
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    Logout ({ commit }) {
      return new Promise((resolve => {
        commit('SET_TOKEN', '')
        commit('SET_PARKING', '')
        commit('SET_INFO', undefined)
        storage.remove('token')
        resolve()
      }))
    }
  }
}

export default user