import request from "../utis/request";

export function login(params){
    return request({
        // url: '/auth/login',
        url: '/unattended/login',
        method: 'post',
        data: params
    })
}

export function info() {
    return request({
        url: '/system/user/getInfo',
        method: 'get'
    })
}

export function modifyPassword (params) {
    return request({
        url:'/project/system/user/modify-password',
        method: 'post',
        data: params
    })
}

export function test (params) {
    return request({
        url:'/unattended/operation/test',
        method: 'get',
        data: params
    })
}