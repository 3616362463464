import Vue  from 'vue'

import {
    Button,
    Input,
    FormModel,
    Card,
    Space,
    notification,
    Row,
    Col,
    Layout,
    Menu,
    Empty,
    Descriptions,
    Dropdown,
    Icon,
    Collapse,
    Tabs,
    Divider,
    Table,
    Modal,
    message,
    Drawer,
    InputNumber,
    Select,
    Radio,
    TreeSelect,
    AutoComplete,
    DatePicker,
    TimePicker,
    Alert,
    Popover,
    ConfigProvider,
    Checkbox,
    Switch,
    Badge,
    Tooltip
} from 'ant-design-vue'
import VueClipboard from 'vue-clipboard2'

Vue.use(Button)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Card)
Vue.use(Space)
Vue.use(Row)
Vue.use(Col)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Empty)
Vue.use(Descriptions)
Vue.use(Dropdown)
Vue.use(Icon)
Vue.use(Collapse)
Vue.use(Tabs)
Vue.use(Divider)
Vue.use(Table)
Vue.use(Modal)
Vue.use(Drawer)
Vue.use(InputNumber)
Vue.use(Select)
Vue.use(Radio)
Vue.use(TreeSelect)
Vue.use(AutoComplete)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Alert)
Vue.use(Popover)
Vue.use(ConfigProvider)
Vue.use(Checkbox)
Vue.use(Switch)
Vue.use(Badge)
Vue.use(VueClipboard)
Vue.use(Tooltip)

Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
