import { message } from "ant-design-vue";

const waitSecond = 12
const timer = {}

const lock = {
  state: {
    callLock: {},
    timer: {},
    second: {}
  },
  mutations: {
    SET_CALL_LOCK(state, { deviceNo, value }){
      state.callLock = { ...state.callLock, [deviceNo]: value }
    },
    SET_SECOND(state, { type, second }){
      state.second = { ...state.second, [type]: second }
    }
  },
  getters: {
    currentCallLock(state){
      return state.callLock
    }
  },
  actions: {
    callExecute({ state, commit }, deviceNo){
      if(state.callLock[deviceNo]){
        if(state.second[deviceNo]){
          message.warn(`操作过于频繁，请于${state.second[deviceNo]}秒后重试`)
        }else{
          message.warn(`请先结束通话，再重试`)
        }
        return false
      }
      return true
    },
    lockAction({ commit }, deviceNo){
      commit('SET_CALL_LOCK', {
        deviceNo, 
        value: true
      })
    },
    setCallLock({ commit, state }, deviceNo) {
      commit('SET_SECOND', {
        type: deviceNo, 
        second: waitSecond,
      })
      if(timer[deviceNo]){
        clearInterval(timer[deviceNo])
      }
      timer[deviceNo] = setInterval(() => {
        if(state.second[deviceNo] === 1){
          clearInterval(timer[deviceNo])
          commit('SET_CALL_LOCK', {
            deviceNo, 
            value: false
          })
        }
        commit('SET_SECOND', {
          type: deviceNo , 
          second: state.second[deviceNo] - 1
        })
      }, 1000)
    }
  }
};
export default lock;
