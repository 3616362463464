import { reasonList, reasonAdd, reasonDelete } from '../../api/reason'

const reason = {
  state: {
    reasons: []
  },
  mutations: {
    SET_LIST: (state, reasons) => {
      state.reasons = reasons
    },
    DELETE_ITEM: (state, id) => {
      if (state.reasons.length > 0) {
        let oldReasons = state.reasons.filter(item => {
          return item.id != id
        })
        state.reasons = oldReasons
      }
    }
  },
  getters: {
    reasons: state => {
      return state.reasons
    },
    getReasonById: state => (id) => {
      return state.reasons.find(reason => reason.id === id)
    },
    getReasonsStr: state => {
      return state.reasons.length > 0 ? state.reasons.map(item => item.reason) : []
    }
  },
  actions: {
    async getReason({commit}) {
      return new Promise((resolve, reject) => {
        reasonList().then(res => {
          commit('SET_LIST', res)
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    async updateReason({ dispatch }, reason) {
      return new Promise((resolve, reject) => {
        reasonAdd(reason).then(() => {
          dispatch('getReason')
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    async deleteReason({commit}, id) {
      return new Promise((resolve, reject) => {
        reasonDelete(id).then(() => {
          commit('DELETE_ITEM', id)
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    }
  }
}

export default reason