import router from './router'
import storage from 'store'
import store from './store'
import notification from 'ant-design-vue/es/notification'

const whiteList = ['Login'] // no redirect whitelist
const loginRoutePath = '/Login'
const defaultRoutePath = '/'

router.beforeEach((to, from, next) => {
  if (storage.get('token')) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath })
    } else {
      if (store.getters.userInfo && store.getters.userInfo.userid) {
        next()
      } else {
        store.dispatch('GetInfo')
            .then(() => {
              next()
            })
            .catch(e => {
              notification.error({
                message: '错误',
                description: '请求用户信息失败，请重试' + e.message
              })
              // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
              store.dispatch('Logout').then(() => {
                next({ path: loginRoutePath, query: { redirect: to.fullPath } })
              })
            })
      }
    }
  } else {
    if (whiteList.includes(to.name)) {
      next()
    } else {
      next({ path: loginRoutePath})
    }
  }
})